@import './variables';

#projects-container {
    padding: 30px;
    min-height: 100vh;
    background-color: $primary-bg-color;
    overflow: scroll;
    #projects-title {
        p {
            color: $gray-blue;
            font-size: 12vw;
            font-weight: 900;
            margin-bottom: 15px;
        }
    }
    #project-cards {
        margin-top: 30px;
        .project-card {
            margin-top: 15px;
            display: flex;
            justify-content: center;
        }
    }
}


@media screen and(min-width: 992px) {
    #projects-container {
        overflow: hidden;
        #projects-title {
            p {
                padding: 0 100px;
                font-size: 85px !important;
            }
        }
        .project-card {
            display: flex;
            justify-content: center;
        }

    }
    
}