@import './variables';

#contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 60vh;
    background-color: $primary-bg-color;
    padding: 30px;
    text-align: center;
    #contact-title {
        margin-bottom: 50px;
        p {
            color: $secondary-bg-color;
            font-size: 12vw;
            font-weight: 900;
        }
    }
    #contact-content {
        font-size: 20px;
        font-weight: 500;
        color: #1c1b20;
        line-height: 26px;
        letter-spacing: 0.5px;
        max-width: 750px;
        .btn {
            margin-top: 20px;
            background-color: $gray-blue;
            color: white;
            padding: 10px 40px;
            font-weight: 700;
            &:hover {
                opacity: 0.7;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    #contact-title {
        p {
            font-size: 85px !important;            
        }
    }
}