@import './variables';

#prog-bar-container {
    padding: 15px 15px 15px 0;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    #skill-logo {
        img {
            width: 40px;
            height: 40px;
            margin-right: 20px;
        }
    }
    #prog-bar-details {
        width: 100%;
        #prog-skill-details {
            padding: 5px;
            display: flex;
            justify-content: space-between;
            .skill {
                font-size: 20px;
                font-weight: 700;
            }
            .percent {
                font-size: 18px;
                font-weight: 600;
            }
        }
        .prog-bar {
            div {
                background-color: white !important;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
                div {
                    background-image: linear-gradient(to right, $gray-blue, #4C386B, $secondary-bg-color);
                }
            }
        }
    }
    
}

@media screen and (min-width: 992px) {
    #skill-logo {
        img {
            width: 45px !important;
            height: 45px !important;
        }
    }
}